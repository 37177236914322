<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <!-- Brand logo-->
        <div class="i-brand-logo">
          <light-vertical-ostrail-logo />
        </div>
        <!-- /Brand logo-->

        <!-- Reset password-->
        <div class="mt-1">
          <b-card-title class="mb-1">
            {{ $t('loginMessages.resetPassword') }} 🔒
          </b-card-title>

          <!-- form -->
          <validation-observer ref="resetRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationRForm"
            >
              <b-form-group label-for="new-password">
                {{ $t('accountSettings.newPassword') }}
                <span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  name="newPassword"
                  rules="min:8|required|password|confirmPassword:@newPasswordRet"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="new-password"
                      v-model="formData.newPasswordValue"
                      :type="passwordFieldTypeNew"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      name="new-password"
                      :placeholder="$t('accountSettings.newPassword')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIconNew"
                        class="cursor-pointer"
                        @click="togglePasswordNew"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label-for="account-retype">
                {{ $t('accountSettings.retypeNewPassword') }}
                <span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  name="newPasswordRet"
                  rules="min:8|required|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="account-retype"
                      v-model="formData.retypePassword"
                      :type="passwordFieldTypeRetype"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      name="retype-password"
                      :placeholder="$t('accountSettings.newPassword')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIconRetype"
                        class="cursor-pointer"
                        @click="togglePasswordRetype"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-button
                type="submit"
                variant="dark"
                block
                pill
                :disabled="showSpinnerFP"
              >
                <b-spinner
                  v-if="showSpinnerFP"
                  small
                />
                <span class="ml-spinner">{{ $t('loginMessages.updatePassword') }}</span>
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <i class="icon-0-icons-dark-step-back" /> {{ $t('loginMessages.backToLogin') }}
            </b-link>
          </p>
          <br>
        </div>
        <!-- /Reset password-->
      </b-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable object-curly-newline */
/* eslint-disable global-require */
import store from '@/store/index'
import { BCard, BLink, BCardTitle, BForm, BFormGroup, BFormInput, BButton } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, password, min, confirmPassword } from '@validations'
import authService from '@/services/authService'
import userService from '@/services/userService'
import LightVerticalOstrailLogo from '@/views/partials/LightVerticalOstrailLogo.vue'

export default {
  components: {
    BCard,
    BLink,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    ValidationProvider,
    ValidationObserver,
    LightVerticalOstrailLogo,
  },

  data() {
    return {
      showSpinnerFP: false,
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      formData: {
        newPasswordValue: '',
        retypePassword: '',
      },
      required, // validation
      password, // validation
      min, // validation
      confirmPassword, // validation
    }
  },

  computed: {
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },

    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },

  created() {
    this.validateCurrentKey()
  },

  methods: {
    validateCurrentKey() {
      authService.setValidationKey({ key: this.$route.params.key }).then(({ data }) => {
        if (data.data[0]) {
          this.$router.replace({ name: 'auth-login' })
        }
      }).catch(error => {
        this.responseCatch(error)
        setTimeout(() => { this.$router.replace({ name: 'auth-login' }) }, 1000)
      })
    },

    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },

    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },

    validationRForm() {
      this.$refs.resetRules.validate().then(success => {
        if (success) {
          this.showSpinnerFP = true

          const formResetData = {
            key: this.$route.params.key,
            new_password: this.formData.newPasswordValue,
            new_password_confirmation: this.formData.retypePassword,
          }

          userService.resetPassword(formResetData).then(({ data }) => {
            this.showSpinnerFP = false
            this.responseSuccess(data.message[0])
            setTimeout(() => { this.$router.replace('/login') }, 500)
          }).catch(error => {
            this.showSpinnerFP = false
            this.responseCatch(error)
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
